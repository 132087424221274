export default {
  meta_title: 'Plugins and ecosystem | PennyLane',
  meta_description:
    'See the available PennyLane plugins, allowing access to quantum simulators and hardware from IBM, Rigetti, Google, and more.',
  meta_image: 'https://assets.cloud.pennylane.ai/pennylane_website/generic/thumbnail.png',
  title: 'Devices and ecosystem',
  text_1:
    'PennyLane is designed from the ground up to be hardware and device agnostic, allowing quantum functions to be easily dispatched to different quantum devices. A single computation can even include multiple quantum devices from different vendors.',
  text_2: 'Build your quantum algorithm once. Run it everywhere.',
  built_devices: {
    title: 'Built-in devices',
    text: 'PennyLane comes with built-in support for several quantum devices:',
    cards: [
      {
        title: 'default.qubit',
        description:
          'A simple state-vector qubit simulator written in Python, with Autograd, JAX, TensorFlow, and Torch backends. <br><br> A good choice for optimizations with a moderate number of qubits and parameters with exact expectation values.',
        link: '',
        img: '',
        img_alt: '',
      },
      {
        title: 'default.mixed',
        description:
          'A mixed-state qubit simulator written in Python, with Autograd, JAX, TensorFlow, and Torch backends. <br><br> A good choice for simulating noisy circuits and quantum channels.',
        link: '',
        img: '',
        img_alt: '',
      },
      {
        title: 'default.gaussian',
        description:
          'A simple quantum photonic simulator written in Python. <br><br> A good choice for optimizing photonic systems.',
        link: '',
        img: '',
        img_alt: '',
      },
      {
        title: 'lightning.qubit',
        description:
          'A fast state-vector qubit simulator written with a C++ backend. <br><br> A good choice for optimizations with a moderate number of qubits and parameters, or when using stochastic expectation values.',
        link: 'https://docs.pennylane.ai/projects/lightning/en/stable/lightning_qubit/device.html',
        img: '',
        img_alt: '',
      },
      {
        title: 'lightning.gpu',
        description:
          'A fast state-vector qubit simulator utilizing the NVIDIA cuQuantum SDK for GPU accelerated circuit simulation. <br><br> A good choice for a large number of qubits and parameters, taking advantage of one or more GPUs for acceleration.',
        link: 'https://docs.pennylane.ai/projects/lightning/en/stable/lightning_gpu/device.html',
        img: '',
        img_alt: '',
      },
      {
        title: 'lightning.kokkos',
        description:
          'A fast state-vector qubit simulator utilizing the Kokkos library for CPU and GPU accelerated circuit simulation. <br><br> A good choice for a large number of qubits and parameters, taking advantage of one or more GPUs for acceleration.',
        link: 'https://docs.pennylane.ai/projects/lightning/en/stable/lightning_kokkos/device.html',
        img: '',
        img_alt: '',
      },
    ],
  },
  plugins: {
    title: 'Plugins',
    text: 'External quantum devices can be easily added to PennyLane by installing plugins. These plugins are installed separately, providing a rich ecosystem integrating popular quantum software development libraries with the hybrid optimization capabilities of PennyLane. <br><br> Below are a list of official PennyLane plugins supported by the PennyLane development team.',
    cards: [
      {
        title: 'Qiskit',
        description:
          'Qiskit is an open-source quantum software framework designed by IBM. Supported hardware backends include the IBM Quantum Experience.',
        link: 'https://docs.pennylane.ai/projects/qiskit',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/qiskit_logo_small.png',
        img_alt: 'qiskit_logo_small',
      },
      {
        title: 'Amazon Braket',
        description:
          'Amazon Braket is a fully managed AWS service that helps researchers, scientists, and developers get started with quantum computing. Amazon Braket provides on-demand access to managed, high-performance quantum circuit simulators, as well as different types of quantum computing hardware.',
        link: 'https://amazon-braket-pennylane-plugin-python.readthedocs.io',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/AWS.png',
        img_alt: 'AWS_AWS_logo_RGB',
      },
      {
        title: 'Cirq',
        description:
          'Cirq is a Python library designed by Google for writing, manipulating, and optimizing quantum circuits and running them against quantum computers and simulators.',
        link: 'https://docs.pennylane.ai/projects/cirq/',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/cirq.png',
        img_alt: 'cirq',
      },
      {
        title: 'Qrack',
        description:
          'Qrack is a GPU-accelerated quantum computer simulator with many novel optimizations including hybrid-stabilizer simulation. Qrack supports quantum just-in-time compilation when used with PennyLane.',
        link: 'https://pennylane-qrack.readthedocs.io/en/latest/',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/qrack.png',
        img_alt: 'qrack',
      },
      {
        title: 'Microsoft QDK',
        description:
          'Microsoft QDK is a library for quantum programming using the .NET Q# quantum programming language. Provides access to the QDK full state simulator to be used with PennyLane.',
        link: 'https://docs.pennylane.ai/projects/qsharp',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/qsharp.png',
        img_alt: 'qsharp',
      },
      {
        title: 'Qulacs',
        description:
          'Qulacs is a high-performance quantum circuit simulator for simulating large, noisy or parametric quantum circuits. Implemented in C/C++ and with Python interface, Qulacs achieved both high speed circuit simulation and high usability.',
        link: 'https://docs.pennylane.ai/projects/qulacs',
        img: '',
        img_alt: '',
      },
      {
        title: 'IonQ',
        description:
          'IonQ is a ion-trap quantum computing company offering access to quantum computing devices over the cloud.',
        link: 'https://docs.pennylane.ai/projects/ionq',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/ionq.png',
        img_alt: 'ionq',
      },
      {
        title: 'AQT',
        description:
          "Access to Alpine Quantum Technologies' ion-trap quantum computer over the cloud.",
        link: 'https://docs.pennylane.ai/projects/aqt',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/aqt.png',
        img_alt: 'aqt',
      },
      {
        title: 'Rigetti Forest',
        description:
          'pyQuil and the Forest SDK are an open-source quantum software framework designed by Rigetti. Supported hardware backends include the Rigetti Aspen QPU.',
        link: 'https://docs.pennylane.ai/projects/rigetti',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/forest.png',
        img_alt: 'forest',
      },
      {
        title: 'Quantum Inspire',
        description:
          'Quantum Inspire (QI) is a quantum computing platform designed and built by QuTech. It provides access to open quantum hardware backends and quantum emulator backends over the cloud.',
        link: 'https://pennylane-quantuminspire.readthedocs.io',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/quantum-inspire-logo.png',
        img_alt: 'quantum-inspire-logo',
      },
      {
        title: 'ProjectQ',
        description:
          'ProjectQ is an open-source quantum compilation framework.',
        link: 'https://docs.pennylane.ai/projects/projectq',
        img: '',
        img_alt: '',
      },
      {
        title: 'Strawberry Fields',
        description:
          "Strawberry Fields is a Python library for simulating continuous variable quantum optical circuits. Combines Strawberry Fields' polished universal simulator suite with PennyLane's automatic differentiation and optimization.",
        link: 'https://docs.pennylane.ai/projects/strawberryfields',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/xanadu_sf.png',
        img_alt: 'xanadu_sf',
      },
      {
        title: 'Orquestra',
        description:
          'Orquestra is a workflow management system for quantum computing.',
        link: 'https://docs.pennylane.ai/projects/orquestra',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/orquestra.png',
        img_alt: 'orquestra',
      },
      {
        title: 'Honeywell',
        description:
          'Honeywell Quantum Solutions provides access to ion-trap quantum computing hardware over the cloud.',
        link: 'https://docs.pennylane.ai/projects/honeywell',
        img: 'https://assets.cloud.pennylane.ai/pennylane_website/pages/plugins/honeywell.png',
        img_alt: 'honeywell',
      },
    ],
  },
  community_plugins: {
    title: 'Community plugins',
    text: `In addition to the official plugins, there are also plugins created by the PennyLane community.  

*To write your own PennyLane-compatible plugin, the best place to start is our overview of the [plugin API](https://docs.pennylane.ai/en/stable/development/plugins.html). Have a plugin you would like to have listed here? Let us know at [support@pennylane.ai](mailto:support@pennylane.ai).*`,
    cards: [
      {
        title: 'Labscript',
        description:
          'This plugin provides a PennyLane device for executing quantum programs using Labscript Suite.',
        link: 'https://github.com/synqs/pennylane-ls',
        img: '',
        img_alt: '',
      },
      {
        title: 'PyQuest',
        description:
          'PyQuest is a Python library that connects to the high-performance mixed state simulator QuEST.',
        link: 'https://github.com/johannesjmeyer/pennylane-pyquest',
        img: '',
        img_alt: '',
      },
    ],
  },
}
